<template>
  <section id="Pago" class="pago">
    <v-container class="mx-auto pago__wrapper">
      <v-row>
        <v-col cols="1" class="d-none d-md-block pb-9">
          <FlechaAtras />
        </v-col>

        <v-col cols="12" md="7"  class="mt-3 mt-sm-0">
          <h1 class="pb-4 d-none d-md-block">
            {{ lenguages[idioma].pago.metodosDePagoYDatosDelPasajero }}
          </h1>

          <v-container class="pago__datosPasajeros mb-5">
            <h2 class="pb-3">Cantidad de pasajeros</h2>
            <v-radio-group
              @change="modificarCantidadDePasajeros"
              v-model="cantidadDePasajerosARegistrar"
              dense
              hide-details
              class="pl-4"
            >
              <div class="d-flex flex-row">
                <v-radio
                  v-for="n in cantidadDePasajeros"
                  :key="n"
                  :label="`${n}`"
                  :value="n"
                  color="#645dc7"
                  class="mb-0 mr-5"
                ></v-radio>
              </div>
            </v-radio-group>
          </v-container>

          <v-container class="pago__datosPasajeros mb-5">
            <h2>{{ lenguages[idioma].pago.datosDeLosPasajeros }}</h2>
            <DatosDePasajeros
              @setPromo="setPromo"
              :itauExperiencia="itauExperienciaDisable"
              :cantidadDePasajeros="
                cantidadDePasajerosARegistrar || cantidadDePasajeros
              "
            />
          </v-container>
          <v-container
            class="pago__datosPasajeros mb-5"
            v-if="vuelosElegidos.ida.data.privado"
          >
            <h2>Datos de facturación</h2>
            <DatosDeFacturacion
              @guardarDatosDeFacturacion="guardarDatosDeFacturacion"
              @editarDatosDeFacturacion="editarDatosDeFacturacion"
            />
          </v-container>

          <v-container class="d-md-none">
            <CardResumenVuelo
              :mostrarDetalles="false"
              :mostrarHeader="false"
              :mostrarEquipaje="false"
              :editarPasajeros="false"
            />
          </v-container>

          <v-container class="pago__metodoPago mb-5 ">
            <v-radio-group v-model="medioDePago">
              <h2>{{ lenguages[idioma].pago.comoVasAPagar }}</h2>
              <!-- 
              <v-radio
                value="webpay"
                :color="colors.mainPurple"
                class="radioItem pa-5"
                :disabled="!usuario"
              >
                <template v-slot:label>
                  <div>
                    <img
                      :src="logos.webpay"
                      alt="logo webpay plus"
                      height="40"
                    />
                  </div>
                </template>
              </v-radio> -->

              <v-radio
                value="flow"
                :color="colors.mainPurple"
                class="radioItem pa-5"
                :disabled="!usuario"
              >
                <template v-slot:label>
                  <div>
                    <img :src="logos.flow" alt="logo flow" height="40" />
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-container>

          <v-container class="pago__metodoPago selecciona-fecha">
            <h2>{{ lenguages[idioma].pago.fechaDeReserva }}</h2>

            <v-dialog
              ref="dialog"
              v-model="modal"
              :persistent="true"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="picker"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :color="colors.mainPurple"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="picker"
                scrollable
                :color="colors.mainPurple"
                :allowedDates="allowedDates"
                locale="es"
              >
                <v-spacer></v-spacer>

                <v-btn
                  class="white--text"
                  large
                  color="rgb(100, 93, 199)"
                  @click="setearFecha"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-container>

          <v-container v-if="usuario">
            <div class="d-flex pt-5">
              <v-checkbox
                v-model="datosIngresadosSonCorrectos"
                :color="colors.mainPurple"
              />
              <h3>{{ lenguages[idioma].pago.declaro }}</h3>
            </div>
            <div class="d-flex pb-5">
              <v-checkbox
                v-model="aceptarTerminos"
                :color="colors.mainPurple"
              />
              <h3>
                {{ lenguages[idioma].pago.terminos }}
              </h3>
            </div>
            <div @click="irAlPago">
              <Boton
                :texto="lenguages[idioma].pago.pagar"
                :deshabilitar="!deshabilitarBotonParaContinuar"
                fontSize="24px"
                heigth="fit-content"
              />
            </div>
          </v-container>
        </v-col>

        <v-col
          cols="4"
          md="4"
          lg="4"
          xl="3"
          class="pago__detalles pl-md-3 d-none d-md-block"
        >
          <h1 class="pb-9">{{ lenguages[idioma].pago.detallesDelVuelo }}</h1>
          <div class="pago__detalles__resumenVuelo pa-2">
            <CardResumenVuelo :editarPasajeros="false" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import DatosDePasajeros from "@/components/FormulariosPasajeros/DatosDePasajeros";
import DatosDeFacturacion from "@/components/FormulariosPasajeros/DatosDeFacturacion";
import FlechaAtras from "@/components/FlechaAtras";
import CardResumenVuelo from "@/components/Cards/CardResumenVuelo";
import Boton from "@/components/Botones/Boton";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import firebase from "firebase";
import axios from "axios";
export default {
  name: "Pago",
  components: {
    DatosDePasajeros,
    DatosDeFacturacion,
    FlechaAtras,
    CardResumenVuelo,
    Boton,
  },
  data() {
    return {
      cantidadDePasajeros: null,
      cantidadDePasajerosARegistrar: 0,
      medioDePago: "flow",
      datosIngresadosSonCorrectos: false,
      aceptarTerminos: false,
      itauExperienciaDisable: false,
      dialog: false,
      picker: "",
      modal: false,
      datosDeFacturacionCompletos: false,
      datosDeFacturacion: "",
    };
  },
  mounted() {
    const { vuelosElegidos } = this;
    const {
      ida: {
        data: { itauExperiencia },
      },
      info: { pasajeros },
    } = vuelosElegidos;
    if (itauExperiencia) this.itauExperienciaDisable = true;
    this.cantidadDePasajeros = pasajeros;
    this.cantidadDePasajerosARegistrar = pasajeros;
  },
  computed: {
    ...mapState("Experiencias", ["moneda", "monedas"]),
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState(["icons", "logos", "colors"]),
    ...mapState("Ventas", [
      "vuelosElegidos",
      "pasajerosAnotados",
      "datosPasajeros",
    ]),
    ...mapState("Auth", ["usuario"]),
    ...mapGetters("Vuelos", ["getVueloById"]),
    ...mapGetters("Experiencias", ["getExperienciaById"]),
    ...mapGetters("Ventas", ["montoTotal"]),
    deshabilitarBotonParaContinuar() {
      const {
        usuario,
        datosIngresadosSonCorrectos,
        pasajerosAnotados,
        aceptarTerminos,
        picker,
        datosDeFacturacionCompletos,
      } = this;
      if (usuario) {
        let checking = !!datosIngresadosSonCorrectos;
        checking = checking && !!pasajerosAnotados;
        checking = checking && !!aceptarTerminos;
        checking = checking && !!picker;

        if (this.vuelosElegidos.ida.data.privado)
          checking = checking && !!datosDeFacturacionCompletos;
        return checking;
      } else return true;
    },
  },
  methods: {
    ...mapMutations("Ventas", [
      "AGREGAR_DATOS_VENTA",
      "VUELOS_ELEGIDOS",
      "SET_FECHA_DE_VUELO",
    ]),
    ...mapMutations("Auth", ["LOADING_ON", "LOADING_OFF"]),
    ...mapActions("Ventas", [
      "agregarVenta",
      "agregarVentaAUsuario",
      "descontarAsientosComprados",
    ]),

    modificarCantidadDePasajeros() {
      const { cantidadDePasajerosARegistrar } = this;
      this.vuelosElegidos.info.cantidadDePasajerosModificados = cantidadDePasajerosARegistrar;
    },

    guardarDatosDeFacturacion(datosDeFacturacion) {
      this.datosDeFacturacion = datosDeFacturacion;
      this.datosDeFacturacionCompletos = true;
    },
    editarDatosDeFacturacion() {
      this.datosDeFacturacion = "";
      this.datosDeFacturacionCompletos = false;
    },

    setearFecha() {
      this.SET_FECHA_DE_VUELO(this.picker);
      this.modal = false;
    },

    allowedDates(value) {
      const diasDeSemanaDisponibles = [];
      let {
        vuelosElegidos: {
          ida: {
            data: { diasDisponibles, fechas, fechasBloqueadas },
          },
        },
      } = this;

      if (!diasDisponibles) diasDisponibles = {};
      if (!fechas) fechas = [];
      if (!fechasBloqueadas) fechasBloqueadas = [];

      diasDisponibles.forEach((d, i) =>
        d.value ? diasDeSemanaDisponibles.push(i) : false
      );
      const fechaCriterio = new Date(value).getTime();
      const hoy = new Date().getTime();
      const diaCriterio = new Date(value).getDay();

      const fechaMayorQueHoy = fechaCriterio >= hoy;
      const consideraFechasPuntuales = fechas.some((f) => {
        return new Date(f).getTime() === fechaCriterio;
      });

      const esUnDiaDeSemanaDisponibles = diasDeSemanaDisponibles.includes(
        diaCriterio
      );

      const inicioDeRangoFechasBloqueadas = new Date(
        fechasBloqueadas[0]
      ).getTime();
      const finDeRangoFechasBloqueadas = new Date(
        fechasBloqueadas[1]
      ).getTime();

      const esUnDiaBloqueadoPorTemporada =
        fechaCriterio <= finDeRangoFechasBloqueadas &&
        fechaCriterio >= inicioDeRangoFechasBloqueadas;

      return (
        (fechaMayorQueHoy &&
          !esUnDiaBloqueadoPorTemporada &&
          esUnDiaDeSemanaDisponibles) ||
        consideraFechasPuntuales
      );
    },

    async setPromo({ codigo }) {
      try {
        const url =
          "https://us-central1-hummingbird-453db.cloudfunctions.net/itau/codigo";
        await axios.get(url + "/" + codigo);
        this.itauExperienciaDisable = false;
        this.$toast.open({
          message: this.lenguages[this.idioma].pago.promocionAplicada,
          position: "top",
          duration: 3000,
        });
      } catch (error) {
        this.$toast.open({
          message: this.lenguages[this.idioma].pago.codigoIncorrecto,
          position: "top",
          duration: 3000,
          type: "error",
        });
      }
    },
    async irAlPago() {
      if (this.deshabilitarBotonParaContinuar) {
        const {
          monedas: { dolar: valorDelDolar },
          vuelosElegidos: {
            info: { pasajeros: cantidadDePasajeros },
            ida: {
              data: { precio, precioEnDolares },
            },
          },
        } = this;
        let montoTotal = 0;
        if (precioEnDolares) {
          montoTotal = precioEnDolares * valorDelDolar;
          montoTotal *= cantidadDePasajeros;
        } else {
          montoTotal = precio * cantidadDePasajeros;
        }

        //montoTotal = 350.33;
        let datosVenta = {
          details: {
            ida: {
              id: this.vuelosElegidos.ida.id,
              data: {
                destino: this.vuelosElegidos.ida.data.destino,
                descripcion: this.vuelosElegidos.ida.data.descripcion,
                tipoDeViaje: this.vuelosElegidos.ida.data.privado
                  ? "privado"
                  : "compartido",
              },
            },
            info: this.vuelosElegidos.info,
          },
          userData: {
            id: this.usuario.uid,
            email: this.usuario.email,
            displayName: this.usuario.displayName,
          },
          date: moment().format("YYYY-MM-DD HH:mm"),
          fecha: this.picker,
        };
        datosVenta.details.listaPasajeros = this.datosPasajeros;
        datosVenta.details.datosDeFacturacion = this.datosDeFacturacion;
        datosVenta.details.medioPago = this.medioDePago;
        datosVenta.details.total = Math.round(montoTotal);
        datosVenta.details.ida.checkInDone = false;
        datosVenta.subject = `Compra de ${datosVenta.details.info.tipoDeViaje
          .toLowerCase()
          .slice(0, -1)}`;
        const finalizar = async () => {
          this.AGREGAR_DATOS_VENTA(datosVenta);
          if (this.medioDePago == "flow") {
            try {
              const { id: ventaID } = await firebase
                .firestore()
                .collection("ventas_init")
                .add(datosVenta);
              this.LOADING_ON();
              const { data } = await axios.post(
                //`http://localhost:5001/hummingbird-453db/us-central1/flow/apiFlow/create_order/${ventaID}`
                `https://us-central1-hummingbird-453db.cloudfunctions.net/flow/apiFlow/create_order/${ventaID}`
              );
              const { redirect } = data;
              window.location = redirect;
            } catch (e) {
              console.log(e);
              this.$toast.error("Algo salió mal ..." + e.message, {
                position: "top",
                duration: 3000,
              });
              this.LOADING_OFF();
            }
          } else if (this.medioDePago == "webpay") {
            window.location = `https://us-central1-hummingbird-453db.cloudfunctions.net/tbk/pago/${montoTotal}?datosVenta=${datosVentaToken}`;
            // window.location = `http://localhost:5001/hummingbird-453db/us-central1/tbk/pago/${montoTotal}?datosVenta=${datosVentaToken}`;
          }
        };

        //Comprobación asientos
        //Data de la compra
        const { ida, vuelta, info } = datosVenta.details;
        const { tipoDeViaje, tipoDeVuelo, pasajeros } = info;
        const { id: idIda } = ida;
        const idVuelta = vuelta ? vuelta.id : null;

        //Vuelos originales
        const docOriginalVueloIda =
          tipoDeViaje === "Vuelos"
            ? await this.getVueloById(idIda).data
            : await this.getExperienciaById(idIda).data;

        const docOriginalVueloVuelta = vuelta
          ? await this.getVueloById(idVuelta).data
          : null;
        //Asientos disponibles
        const asientosDisponiblesIda =
          (await docOriginalVueloIda.capacidad) - docOriginalVueloIda.ventas;
        const asientosDisponiblesVuelta = vuelta
          ? (await docOriginalVueloVuelta.capacidad) -
            docOriginalVueloVuelta.ventas
          : null;

        finalizar();
      } else {
        this.$toast.warning("Debe llenar todos los campos", {
          position: "top",
          duration: 3000,
        });
      }
    },
  },
  title() {
    return `Finalizar compra - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#pago,
.pago {
  background: $main-white;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 95px;
  &__metodoPago,
  &__datosPasajeros {
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    background: $main-white;
    .radioItem {
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      height: 103px;
    }
    p {
      font-size: 12px;
    }
  }
  &__detalles {
    color: $main-black;
    &__resumenVuelo {
      background: $main-white;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
    }
  }
}

@media (min-width: 960px) {
  #Pago,
  .pago {
    background-color: $gray-background;
    padding-top: 100px;
    &__metodoPago,
    &__datosPasajeros {
      max-width: 575px;
      p {
        font-size: 18px;
      }
    }
  }
}
</style>
