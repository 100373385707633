<template>
  <div class="py-3 px-5">
    <div class="form" v-show="!ocultarForm">
      <h3>
        Seleccione empresa o persona
      </h3>
      <v-radio-group v-model="datosDeFacturacion.tipoDeUsuario">
        <div class="d-flex align-center">
          <v-radio
            color="#645dc7"
            :label="`Empresa`"
            value="empresa"
            class="mb-0 mr-3"
          ></v-radio>
          <v-radio
            color="#645dc7"
            :label="`Persona`"
            value="persona"
            class="mb-0"
          ></v-radio>
        </div>
      </v-radio-group>

      <h3>
        Nombre o Razón social
      </h3>
      <v-text-field
        outlined
        dense
        color="#645dc7"
        v-model="datosDeFacturacion.razonSocial"
        prepend-inner-icon="mdi-account"
      ></v-text-field>
      <h3>
        RUT
      </h3>
      <v-text-field
        outlined
        dense
        color="#645dc7"
        v-model="datosDeFacturacion.rut"
        prepend-inner-icon="mdi-card-account-details"
      ></v-text-field>
      <h3>
        Dirección
      </h3>
      <v-text-field
        outlined
        dense
        color="#645dc7"
        v-model="datosDeFacturacion.direccion"
        prepend-inner-icon="mdi-map-marker"
      ></v-text-field>

      <div v-show="datosDeFacturacion.tipoDeUsuario == 'empresa'">
        <h3>
          Giro comercial
        </h3>
        <v-text-field
          outlined
          dense
          color="#645dc7"
          v-model="datosDeFacturacion.giro"
          prepend-inner-icon="mdi-format-list-group"
        ></v-text-field>
      </div>

      <div class="footer">
        <v-btn
          class="footer__boton"
          color="transparent"
          elevation="0"
          @click="guardarDatosDeFacturacion"
          :disabled="!datosCompletos"
        >
          <h2>
            Continuar
          </h2>
        </v-btn>
      </div>
    </div>

    <div v-show="ocultarForm">
      <div>
        {{ datosDeFacturacion.razonSocial }}
      </div>
      <div>
        {{ datosDeFacturacion.rut }}
      </div>
      <div>
        {{ datosDeFacturacion.direccion }}
      </div>
      <div>{{ datosDeFacturacion.giro }}</div>

      <div class="footer">
        <v-btn
          class="footer__boton pl-0"
          color="transparent"
          elevation="0"
          @click="editarDatosDeFacturacion"
        >
          <h2>
            Editar
          </h2>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DatosDeFacturacion",
  props: ["itauExperiencia"],
  data() {
    return {
      datosDeFacturacion: {
        tipoDeUsuario: "empresa",
        razonSocial: "",
        rut: "",
        direccion: "",
        giro: "",
      },
      ocultarForm: false,
    };
  },
  computed: {
    ...mapState("Ventas", ["vuelosElegidos"]),
    datosCompletos() {
      const { datosDeFacturacion } = this;
      if (datosDeFacturacion.tipoDeUsuario !== "empresa")
        delete datosDeFacturacion.giro;
      const todoBien = Object.values(datosDeFacturacion).every(Boolean);
      return todoBien;
    },
  },
  methods: {
    guardarDatosDeFacturacion() {
      if (this.datosCompletos) {
        this.$emit("guardarDatosDeFacturacion", this.datosDeFacturacion);
        this.ocultarForm = true;
      }
    },
    editarDatosDeFacturacion() {
      this.$emit("editarDatosDeFacturacion");
      this.ocultarForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.loginOverlay {
  &__fbBtn,
  &__igBtn {
    padding: 4rem 16px;
    text-transform: initial;
    font-size: 13px;
    font-weight: 700;
    height: 55px;
    img {
      height: 24px;
    }
    span {
      padding-left: 10px;
    }
  }
}

.footer {
  &__boton {
    color: $main-purple;
    text-transform: initial;
    letter-spacing: initial;
    &:hover {
      cursor: pointer;
    }
  }
}
.active {
  color: $main-purple !important;
}
</style>
