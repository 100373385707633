<template>
  <div :class="{ 'pa-4': usuario }">
    <v-carousel
      hide-delimiter-background
      hide-delimiters
      :show-arrows="false"
      height="fit-content"
      v-model="pasajeroVisible"
      touchless
    >
      <!-- OVERLAY -->
      <v-overlay
        class="loginOverlay text-center pa-2"
        v-if="!usuario"
        :absolute="true"
        :value="true"
        :opacity="0.9"
      >
        <h3>{{ lenguages[idioma].pago.paraFinalizar }}:</h3>
        <v-btn
          class="loginOverlay__fbBtn rounded-xl py-3 mt-3 mr-sm-2"
          color="#2582D8"
          dark
          @click="signInWithFb"
        >
          <img :src="logos.facebook" />
          <span>{{ lenguages[idioma].pago.iniciarConFB }}</span>
        </v-btn>

        <v-btn
          class="loginOverlay__igBtn rounded-xl py-3 mt-3"
          color="red"
          dark
          @click="signInWithGoogle"
        >
          <v-icon>mdi-google</v-icon>
          <span>{{ lenguages[idioma].pago.iniciarConGoogle }}</span>
        </v-btn>
      </v-overlay>

      <!-- FORMULARIO -->
      <v-carousel-item
        v-for="(pasajero, i) in vuelosElegidos.ida.data
          .cantidadDePasajerosModificados
          ? vuelosElegidos.ida.data.cantidadDePasajerosModificados
          : cantidadDePasajeros"
        :key="i"
      >
        <v-sheet light>
          <h3>
            {{
              lenguages[idioma].misDatos.nombreYApellido || "Nombre y apellido"
            }}
          </h3>
          <v-text-field
            outlined
            dense
            :color="colors.mainPurple"
            placeholder="Ejemplo Pedro Martinez"
            v-model="nombre"
            :rules="rules.name.concat(rules.required)"
            validate-on-blur
          ></v-text-field>

          <h3>
            {{ lenguages[idioma].pago.tipoYNumeroDeDocumento }}
          </h3>
          <v-row no-gutters align="baseline">
            <v-col cols="12" md="4">
              <v-select
                dense
                :items="documentos"
                v-model="documento"
                outlined
                :color="colors.mainPurple"
                :item-color="colors.mainPurple"
                :rules="rules.documento.concat(rules.required)"
              />
            </v-col>
            <v-col
              v-if="documento != documentos[0].text"
              class="d-md-none"
              cols="12"
            >
              <h3>N° de Documento</h3>
            </v-col>

            <v-col cols="1" class="d-none d-md-block"></v-col>
            <v-col v-if="documento === 'RUT'" cols="5" md="3">
              <v-text-field
                dense
                :color="colors.mainPurple"
                placeholder="12345678"
                v-model="rutSinDigitoVerificador"
                :rules="rules.rut.concat(rules.required)"
                outlined
                validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col v-if="documento === 'RUT'" class="text-center" cols="1">
              <h2>-</h2>
            </v-col>
            <v-col v-if="documento === 'RUT'" cols="2" md="1">
              <v-text-field
                outlined
                dense
                :color="colors.mainPurple"
                placeholder="0"
                v-model="digitoVerificador"
                :rules="rules.digitoVerificador.concat(rules.required)"
              ></v-text-field>
            </v-col>
            <v-col v-else-if="documento === 'Pasaporte'">
              <v-text-field
                dense
                :color="colors.mainPurple"
                placeholder="ABC12345678"
                v-model="numeroPasaporte"
                :rules="rules.required"
                outlined
                validate-on-blur
                prepend-inner-icon="mdi-passport"
              ></v-text-field>
            </v-col>
          </v-row>

          <h3>{{ lenguages[idioma].pago.paisDeOrigen }}</h3>
          <v-select
            dense
            :items="paises"
            v-model="paisDeOrigen"
            outlined
            :color="colors.mainPurple"
            :item-color="colors.mainPurple"
            :rules="rules.required"
          />

          <h3>{{ lenguages[idioma].pago.numeroMovil }}</h3>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                outlined
                dense
                :color="colors.mainPurple"
                placeholder="Tu teléfono móvil"
                v-model="telefono"
                type="number"
                prepend-inner-icon="mdi-phone"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>

          <h3>{{ lenguages[idioma].pago.peso }}</h3>
          <v-text-field
            outlined
            dense
            :color="colors.mainPurple"
            placeholder="Peso del pasajero"
            prepend-inner-icon="mdi-weight-kilogram"
            v-model="peso"
            :rules="rules.required"
          ></v-text-field>

          <!-- Checkbox para confirmar los cambios a los datos del usuario -->
          <div v-if="seHanEditadoDatos && !pasajeros.length">
            <v-checkbox
              v-model="actualizarDatos"
              :label="lenguages[idioma].pago.actualizar"
              :color="colors.mainPurple"
            ></v-checkbox>
          </div>
        </v-sheet>
      </v-carousel-item>

      <!-- LISTA DE PASAJEROS -->
      <v-carousel-item v-if="pasajerosGuardados">
        <v-sheet light>
          <table>
            <tr
              v-for="(pasajero, i) of pasajeros"
              :key="i"
            >
              <td class="pr-2">{{ pasajero.nombre }}</td>
              <td class="pr-2">{{ pasajero.rut }}</td>
              <td class="pr-2 d-none d-md-block">{{ pasajero.telefono }}</td>
              <!-- <td>
                <v-btn x-small :color="colors.mainPurple" dark fab>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td> -->
            </tr>
          </table>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <!-- STEPPER Y BOTON PARA CONTIUNAR -->
    <div
      v-if="
        pasajeros.length != (cantidadDePasajeros || cantidadDePasajeros) &&
          usuario
      "
      class="footer d-flex justify-space-between align-center"
    >
      <v-btn
        class="footer__boton"
        color="transparent"
        elevation="0"
        @click="agregarPasajero"
        :disabled="
          vuelosElegidos.ida.data.promocionXPlus1
            ? !validacionFormulario ||
              pasajeros.length === cantidadDePasajeros + 1
            : !validacionFormulario || pasajeros.length === cantidadDePasajeros
        "
      >
        <h2>
          {{
            vuelosElegidos.ida.data.promocionXPlus1
              ? pasajeros.length === cantidadDePasajeros
                ? lenguages[idioma].pago.continuar
                : lenguages[idioma].pago.siguiente
              : pasajeros.length === cantidadDePasajeros - 1
              ? lenguages[idioma].pago.continuar
              : lenguages[idioma].pago.siguiente
          }}
        </h2>
      </v-btn>
      <div>
        <v-icon
          v-for="(pasajero, i) in vuelosElegidos.ida.data.promocionXPlus1
            ? cantidadDePasajeros + 1
            : cantidadDePasajeros"
          :key="i"
          size="10"
          :color="colors.grayDisabled"
          :class="{ active: i == pasajeroVisible }"
          >mdi-circle</v-icon
        >
      </div>
      <div></div>
      <div v-if="vuelosElegidos.ida.data.itauExperiencia">
        <div class="d-none d-sm-block">
          <v-btn color="deep-purple" @click="dialog = true">
            <p style="color: white">
              {{ lenguages[idioma].pago.codigoPromocional }}
            </p>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="vuelosElegidos.ida.data.itauExperiencia" class="d-sm-none pt-4">
      <v-btn color="deep-purple" @click="dialog = true">
        <p style="color: white">
          {{ lenguages[idioma].pago.codigoPromocional }}
        </p>
      </v-btn>
    </div>

    <!-- Modal -->

    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-10">
        <v-text-field
          :label="lenguages[idioma].pago.codigoPromocional"
          color="deep-purple"
          v-model="codigo"
          @keyup.enter="setPromo"
        />
        <v-btn color="deep-purple" @click="setPromo">
          <p style="color : white">{{ lenguages[idioma].pago.aceptar }}</p>
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import worldCountries from "@/helpers/worldCountries.js";
export default {
  name: "DatosPasajeros",
  components: {
    Boton,
  },
  props: ["itauExperiencia", "cantidadDePasajeros"],
  data() {
    return {
      codigo: "",
      dialog: false,
      pasajeroVisible: 0,
      autocompletar: true,
      actualizarDatos: false,
      documentos: [
        { text: "-Selecciona documento-", disabled: true },
        { text: "RUT", disabled: false },
        { text: "Pasaporte", disabled: false },
      ],
      paises: [],
      //Datos Usuario
      nombre: "",
      documento: "-Selecciona documento-",
      rutSinDigitoVerificador: "",
      digitoVerificador: "",
      numeroPasaporte: "",
      paisDeOrigen: "Chile",
      telefono: "569",
      peso: "",
      //Lista de pasajeros
      pasajeros: [],
      enviarCorreoATodos: false,
      pasajerosGuardados: false,
    };
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState(["colors", "logos"]),
    ...mapState("Ventas", ["vuelosElegidos"]),
    ...mapState("Auth", ["usuario"]),
    rules() {
      let reglas = {
        name: [
          // (v) => !!v || "Nombre requerido",
          (v) =>
            v.length >= 7 ||
            "El nombre completo del pasajero debe tener al menos 7 caracteres",
        ],
        email: [
          // (v) => !!v || "Correo electrónico requerido",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "El correo electrónico ingresado no es válido",
        ],
        documento: [
          (v) => v != this.documentos[0].text || "Selecciona un documento",
        ],
        rut: [(v) => (v.length <= 8 && v.length >= 7) || "Rut no válido"],

        digitoVerificador: [(v) => v.length === 1 || "1 Dígito"],
        required: [(v) => !!v || "Campo requerido"],
      };

      return reglas;
    },
    //Booleanos
    validacionFormulario() {
      if (
        this.nombre.trim() == "" ||
        this.documento === this.documentos[0].text
      ) {
        return false;
      } else if (
        this.documento === "RUT" &&
        (this.rutSinDigitoVerificador.trim() == "" ||
          this.digitoVerificador.trim() == "")
      ) {
        return false;
      } else if (
        this.documento === "Pasaporte" &&
        this.numeroPasaporte.trim() == ""
      ) {
        return false;
      } else if (this.peso.trim() == "" && +this.peso < 1) {
        return false;
      } else return true;
    },
    seHanEditadoDatos() {
      if (
        this.usuario &&
        ((this.usuario.displayName != this.nombre &&
          this.nombre.trim() != "") ||
          (this.rutIngresadoEsDistintoAlDelUsuario &&
            (this.rutSinDigitoVerificador != "" ||
              this.digitoVerificador != "")) ||
          (this.usuario.pasaporte != this.numeroPasaporte &&
            this.numeroPasaporte.trim() != "") ||
          (this.usuario.nacionalidad != this.paisDeOrigen &&
            this.paisDeOrigen.trim() != "") ||
          (this.usuario.telefono != this.telefono &&
            this.telefono.trim() != "" &&
            this.telefono.trim() != "569"))
      ) {
        return true;
      } else return false;
    },
    rutIngresadoEsDistintoAlDelUsuario() {
      if (this.usuario) {
        const rutPerfil = this.usuario.rut;
        const rutIngresado = `${this.rutSinDigitoVerificador.trim()}-${this.digitoVerificador
          .trim()
          .toLocaleLowerCase()}`;

        return rutPerfil != rutIngresado;
      } else return false;
    },
  },
  methods: {
    async setPromo() {
      const { codigo } = this;
      this.dialog = false;
      this.$emit("setPromo", { codigo });
    },
    ...mapActions("Auth", [
      "signInWithGoogle",
      "signInWithFb",
      "actualizarDatosUsuario",
      "getDataFromRedirect",
    ]),

    ...mapActions("Ventas", ["set_Promo"]),

    ...mapMutations("Ventas", ["PASAJEROS_ANOTADOS", "LISTA_DE_PASAJEROS"]),
    async agregarPasajero() {
      const telefono = this.telefono;

      if (this.validacionFormulario) {
        const datosUsuario = {
          nombre: this.nombre.trim(),
          documento: this.documento,
          rut: `${this.rutSinDigitoVerificador.trim()}-${this.digitoVerificador
            .trim()
            .toLocaleLowerCase()}`,
          pasaporte: this.numeroPasaporte,
          pais: this.paisDeOrigen,
          telefono: +telefono,
          peso: this.peso.trim(),
        };
        this.pasajeros.push(datosUsuario);
        if (this.actualizarDatos && this.seHanEditadoDatos) {
          this.actualizarDatosUsuario(datosUsuario);
        }

        this.pasajeroVisible++;

        this.nombre = "";
        this.rutSinDigitoVerificador = "";
        this.digitoVerificador = "";
        this.telefono = "569";
        this.peso = "";
        this.actualizarDatos = false;

        if (this.pasajeros.length == this.cantidadDePasajeros) {
          this.LISTA_DE_PASAJEROS(this.pasajeros);
          this.PASAJEROS_ANOTADOS(true);
          this.pasajerosGuardados = true;
        }
      }
    },
    ocultarBotonSiguiente() {
      this.pasajeroVisible + 1 === this.cantidadDePasajeros ? true : false;
    },
    autocompletarDatosDeUsuario() {
      if (this.usuario && this.autocompletar) {
        const separarRut = this.usuario.rut ? this.usuario.rut.split("-") : "";

        this.nombre = this.usuario.displayName ? this.usuario.displayName : "";

        this.rutSinDigitoVerificador = separarRut ? separarRut[0] : "";

        this.digitoVerificador = separarRut ? separarRut[1] : "";

        this.pasaporte = this.usuario.pasaporte ? this.usuario.pasaporte : "";

        this.paisDeOrigen = this.usuario.nacionalidad
          ? this.usuario.nacionalidad
          : "Chile";

        this.telefono = this.usuario.telefono ? this.usuario.telefono : "569";

        if (this.usuario.pasaporte != "") this.documento = "Pasaporte";
        if (this.usuario.rut != "") this.documento = "RUT";

        this.autocompletar = false;
      }
    },
  },
  mounted() {
    this.autocompletarDatosDeUsuario();
    this.paises = worldCountries;
  },
  updated() {
    this.autocompletarDatosDeUsuario();
  },
};
</script>

<style lang="scss" scoped>
.loginOverlay {
  &__fbBtn,
  &__igBtn {
    padding: 4rem 16px;
    text-transform: initial;
    font-size: 13px;
    font-weight: 700;
    height: 55px;
    img {
      height: 24px;
    }
    span {
      padding-left: 10px;
    }
  }
}

.footer {
  &__boton {
    color: $main-purple;
    text-transform: initial;
    letter-spacing: initial;
    &:hover {
      cursor: pointer;
    }
  }
}
.active {
  color: $main-purple !important;
}
</style>
